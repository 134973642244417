import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
  Toolbar, Typography, TextField, InputAdornment,
  Snackbar, Alert, LinearProgress, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow,
  Stack,
  Link,
  Button,
  Modal,
  Box,
  Grid,
  styled,
  Avatar
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { RiderKycApi } from "../../../API/RiderKycApi";
import { RiderKycList } from "../../../@Types/ComponentsInterface/RiderDetailsKycProps";
import "./TlCmTable.css";
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 740,
  maxHeight: "90vh", // Set maxHeight
  overflowY: "auto", // Enable vertical scroll
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: "#00CD5B",
  width: theme.spacing(14),
  height: theme.spacing(14),
  fontSize: "30px",
  fontWeight: "bold",
  color: "#FFFFFF",
  alignItems: "center",
  marginBottom: "6px",
  borderStyle: "solid",
  borderWidth: "5px",
  borderColor: "white",
  borderRadius: "50%",
  boxShadow: "0 0 0 1px #00000040",
}));
const tableHeaderCellStyle = {
  fontWeight: 600,
  fontSize: "14px",
  color: "#455A64",
};

const tableBodyCellStyle = {
  fontWeight: 400,
  fontSize: "14px",
  color: "#A4A2A2",
};

export default function RiderDetailTracking() {
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [alluserData, setAllUserData] = useState<RiderKycList[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const pageRange = 5;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null); // To store the selected row
  const [selectedRowDetail, setSelectedRowDetail] = useState<any>(null); // To store the selected row
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const formRef = useRef<HTMLFormElement | null>(null);
  

  const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row); // Store the selected row data

  };
  
  const handleOpenDetailModal = (row: any) => {
    setSelectedRowDetail(row)
    setOpenDetailModal(true);
    handleCloseTooltip(); // Close the tooltip
  };
  const handleCloseDetailModal = () => {
    setOpenDetailModal(false);
  };
  const handleCloseTooltip = () => {
    setAnchorEl(null);
    setSelectedRow(null); // Reset selected row when the menu is closed

  };
  const navigateToHome = () => {
    navigate("/Dashboard");
  };

  const handleClose = () => {
    setShowSnackbar(false);
  };
  function avatarName(fullName: string | null) {
    if (fullName === null) {
      return "Default";
    }
    return fullName.charAt(0);  // Returns the first letter of the full name
  }
  useEffect(() => {
    setLoading(true);
    RiderKycApi.api?.alluserData(searchVal, page + 1, rowsPerPage)
      .then((response) => {
        setLoading(false);
        if (response.data.users.length > 0) {
          setAllUserData(response.data.users);
          setTotalCount(response.data.records);

        } else {
          setAllUserData([]);
          setTotalCount(1);
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error: ", error);
      });
  }, [page, rowsPerPage, searchVal]);

  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    setPage(Math.max(0, Math.min(newPage, pageCount - 1)));
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`pagination-number ${page === pageNumber ? "active" : "unactive"
            }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };


  const handleCreateuser = () => {
    //   setUserId(uid);
    navigate("/createuser");
  };

  function capitalizeFirstLetter(str: string | undefined): string {
    if (!str) return "";
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate(); // Get the day (1-31)

    // Define month abbreviations
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const monthIndex = date.getMonth(); // Get the month index (0-11)
    const year = date.getFullYear(); // Get the year

    return `${day}/${monthNames[monthIndex]}/${year}`; // Format as "Date/DD/Mon/YYYY"
  };


  const handleSelectChange = (e: any) => {
    // Get the selected value from the event
    const selectedValue = e.target.value;


    // Navigate to the /changetl page, passing the selected value as state
    navigate("/changetl", {
      state: { selectedValue: selectedValue }, // Passing selected value in the state
    });
  };

  const handlePunchDetail = (row: any) => {
    const userId = row.userId;
    const name = row.name;
    const mobile = row.mobile;


    navigate("/punchInTable", {
      state: { userId, name, mobile },
    });
  };
  const handleFieldVisit = (row: any) => {
    const userId = row.userId;
    const name = row.name;
    const mobile = row.mobile;


    navigate("/fieldVisitReport", {
      state: { userId, name, mobile },
    });
  };
  const token = localStorage.getItem("access_token");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    
    const userId = selectedRowDetail.userId;

    try {
      const response = await fetch(
        `https://stag-micro.bcykal.com/mobycy/clientDash/update/disableUser?id=${userId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.ok) {
        setShowSuccessSnackbar(true);

        // Close the modal (assuming you have a function to handle that)
        setTimeout(() => {
          handleCloseDetailModal(); // Close the modal
  
          // Optionally reload the page
          window.location.reload();
        }, 3000); // 3 seconds delay
      } else {
        const errorData = await response.json();
        setSnackbarMessage(errorData.message || "Error disabling user");
        setShowErrorSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Network error");
      setShowErrorSnackbar(true);
    }
  };
  


  return (
    <div style={{ height: "100%", width: "100%" }} className="border-10 bg-gradient-to-r from-slate-100 to-slate-50">
      <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} className="bg-gradient-to-r from-slate-100 to-slate-50">
        <Typography sx={{ flex: "1 1 100%", textAlign: "left" }} variant="h6" id="tableTitle" component="div">
          <a onClick={navigateToHome} className="cursor-pointer text-black">
            <ArrowBackIcon className="mr-4 mb-1 ml-7" />
          </a>
          <b>All User Report</b>
        </Typography>
        <Typography sx={{ flex: "1 1 100%", textAlign: "right", marginRight: "48px" }} component="div">
          <HomeIcon className="mb-1" /> {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span> {" / "} All User Report
        </Typography>
      </Toolbar>

      <div className="border-0 rounded-3xl pt-8 m-4 bg-white ml-12 mr-12">
        <div className="flex justify-between mb-4 pl-12 pr-8">
          <div className="flex">
            <TextField
              placeholder="Search Name or Mobile Number"
              variant="outlined"
              onChange={(search) => setSearchVal(search.target.value)}
              sx={{ width: 330 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="flex">
            <select
              id="merchant-select"
              style={{
                border: "1px solid #c0baba",
                padding: 3,
                borderRadius: 3,
                height: 32,
                marginRight: 10,
              }}
              onChange={(e) => handleSelectChange(e)}
            >
              <option value="" selected>Change Mapping </option>
              <option value={"0"}>Change TL List</option>
              <option value={"1"}>Change CM List</option>
              <option value={"2"}>Change CH List</option>
              <option value={"3"}>Change OH List</option>
              <option value={"4"}>Change BH List</option>
            </select>
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleCreateuser();
              }}
            >
              <Button
                sx={{
                  backgroundColor: "#00CD5B",
                  width: '150px',
                  "&:hover": {
                    backgroundColor: "#63dd99",
                  },
                }}
                className="bg-green-500 hover:bg-green-400"
                fullWidth
                size="small"
                variant="contained"
                type="submit"
              >
                Create User
              </Button>
            </Link>
          </div>
        </div>
        {loading ? (
          <LinearProgress
            className="pl-4 pr-4"
            sx={{
              backgroundColor: "white",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#00CD5B",
              },
            }}
          />
        ) : (
          <>
            <TableContainer className="pl-5 pr-5" style={{ width: "100%", overflowX: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={tableHeaderCellStyle}>User ID</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Name</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Mobile</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Authority</TableCell>
                    <TableCell style={tableHeaderCellStyle}>User Action</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Registration Date</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {alluserData.map((row: any) => (
                    <TableRow key={row.userId}>
                      <TableCell style={tableBodyCellStyle}><p style={{ margin: 0, color: "#000" }}>{row.userId}</p></TableCell>
                      <TableCell style={tableBodyCellStyle}><p style={{ margin: 0, color: "#000" }}>{capitalizeFirstLetter(row.name)}</p></TableCell>

                      <TableCell style={tableBodyCellStyle}><p style={{ margin: 0, color: "#000" }}>{row.mobile}</p></TableCell>
                      <TableCell style={tableBodyCellStyle}><p style={{ margin: 0, color: "#000" }}>
                      {row.authority.replace(/_/g, ' ')} {/* Replace underscores with spaces */}

                        </p>
                        </TableCell>
                      <TableCell style={tableBodyCellStyle}><p style={{ margin: 0, color: "#000" }}>
                        
                       
                      <span
                                style={{
                                  color: row.enable ? 'green' : 'red', // Green if true, Red if false
                                  fontWeight: 'bold', // Optional: Make the text bold
                                }}
                              >
                                {row.enable ? 'Enabled' : 'Disabled'} {/* Show text based on value */}
                              </span>
                        
                        </p></TableCell>
                      <TableCell style={tableBodyCellStyle}><p style={{ margin: 0, color: "#000" }}>{formatDate(row.createdTime)}</p>
                      </TableCell>
                      <TableCell style={tableBodyCellStyle}>
                        <IconButton size="small" aria-label="More actions"
                          onClick={(event) => handleClick(event, row)} // Pass row to handleClick

                        >
                          <MoreVertIcon />
                        </IconButton>

                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseTooltip}>
                          <MenuItem
                            onClick={() => {
                              handleOpenDetailModal(selectedRow); // Pass the row directly
                            }}
                          >
                            <VisibilityIcon fontSize="small" style={{ marginRight: "10px" }} /> User Detail
                          </MenuItem>
                          <MenuItem onClick={() => {
                            handlePunchDetail(selectedRow); // Use the stored selectedRow
                            handleClose();
                          }}>
                            <AccessTimeIcon fontSize="small" style={{ marginRight: "10px" }} /> Punch In Report
                          </MenuItem>

                          <MenuItem onClick={() => {
                            handleFieldVisit(selectedRow); // Use the stored selectedRow
                            handleClose();
                          }}>
                            <LocationOnIcon fontSize="small" style={{ marginRight: "10px" }} /> Field Visit Report
                          </MenuItem>
                        </Menu>
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="pl-6 pt-4 pb-2 pr-16">
              <div className="pagination" style={{ display: "flex", justifyContent: "space-between" }}>
                <select
                  className="pl-2 pr-2 rounded"
                  value={rowsPerPage}
                  onChange={(e) => setRowsPerPage(Number(e.target.value))}
                  style={{ backgroundColor: "#EBEBEB", fontFamily: "Rubik", fontSize: "16px" }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                <div>
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 0}
                    style={{
                      fontFamily: "Rubik",
                      fontSize: "20px",
                      marginRight: "6px",
                    }}
                  >
                    {"<"}
                  </button>
                  {renderPageNumbers()}
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page >= pageCount - 1}
                    style={{
                      fontFamily: "Rubik",
                      fontSize: "20px",
                      marginLeft: "6px",
                    }}
                  >
                    {">"}
                  </button>
                </div>
              </div>
            </div>
            <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleClose}>
              <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">No data found</Alert>
            </Snackbar>
          </>
        )}
      </div>
      {/* Modal for User Detail */}
      <Modal open={openDetailModal} onClose={handleCloseDetailModal}>
      
          <Box sx={style}>
            <div className="w-full mb-3">
            <Typography variant="h6" id="tableTitle" component="div">

              <b> User Details </b>
            </Typography>
            </div>
            <form ref={formRef} onSubmit={handleSubmit}>
            <div className="border rounded-xl border-green-500 " >
              <div className=" h-32 relative"  >
                <div className="rounded-t-xl	 border-green-500 h-20 bg-[#e5faee] "  >
                  <div className="flex items-center absolute bottom-0	w-full">
                    {selectedRowDetail && selectedRowDetail.name ? (
                      <StyledAvatar aria-label="recipe" className=" p-0.5 ml-8 ">
                        {avatarName(selectedRowDetail?.name).toUpperCase()}
                      </StyledAvatar>
                    ) : (
                      <StyledAvatar aria-label="recipe" className=" p-0.5 ml-8 ">
                        Z
                      </StyledAvatar>
                    )}
                  </div>
                </div>
              </div>
              <div className="ml-12 mx-3 pr-8 pb-8 mb-4">
                <Grid container spacing={5} className="w-full flex justify-between">
                  <Grid item md={6}>
                    <p className="text-left mb-4 text-sm font-bold">Name</p>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="w-full"
                      value={selectedRowDetail?.name || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <p className="text-left mb-4 text-sm font-bold">Username</p>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="w-full"
                      value={selectedRowDetail?.username || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={5} className="w-full flex justify-between">
                  <Grid item md={6}>
                    <p className="text-left mb-4 text-sm font-bold">Email</p>
                    <TextField
                      id="outlined"
                      variant="outlined"
                      className="w-full"
                      value={selectedRowDetail?.email ? new Date(selectedRowDetail.dob).toLocaleDateString() : ''}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <p className="text-left mb-4 text-sm font-bold">Mobile</p>
                    <TextField
                      value={selectedRowDetail?.mobile || ''}
                      className="w-full"
                      variant="outlined"
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>

               

                
                <div className="flex justify-end mr-4 mt-6">
                
                <Button
        variant="contained"
        type="submit"
        sx={{
          textTransform: "none",
          bgcolor: selectedRowDetail?.enable ? "#FF725E" : "#4CAF50", // Red for Disable, Green for Enable
          py: 1,
          px: 10,
          mt: 2,
          "&:hover": { bgcolor: selectedRowDetail?.enable ? "#FF725E95" : "#4CAF5095", },
        }}
      >
       {selectedRowDetail?.enable ? "Disable" : "Enable"}
      </Button>
                </div>
              </div>
            </div>
            </form>
           
          </Box>

      </Modal>
      <Snackbar open={showSuccessSnackbar} autoHideDuration={6000} onClose={handleClose}>
          <Alert sx={{ width: "100%" }} onClose={handleClose} severity="success">
            Disabled SUCCESSFULLY!
          </Alert>
        </Snackbar>
        <Snackbar open={showErrorSnackbar} autoHideDuration={6000} onClose={handleClose}>
          <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">
            {SnackbarMessage.replace(/_/g, " ")}
          </Alert>
        </Snackbar>
    </div>
  );
}
